.feed {
    max-width: 852px;
    margin: auto;
    padding: 0 10px;
    .show-more{
        @include flex(5px, center, center);
        color: $main7;
        cursor: pointer;
        font-size: 24px;
    }
}

.post {
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 10px;
    border-bottom: 2px solid $main3;

    p {
        margin: 0;
    }

    .open-comments {
        width: 100%;
        height: 40px;
        background-color: $main3;
        border-radius: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
    }
    
    .top-comment-header {
        font-size: 16px;
        font-weight: bold;
    }
    
    .top-comment {
        width: 100%;
        padding: 10px;
        background-color: $main3;
        border-radius: 10px;
        @include flex(10px,flex-start,flex-start,column);
        cursor: pointer;
    }
    
    .top-comment-half {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        .comments{
            font-size: 20px;
            font-weight: 900;
        }
        .comments-amount{
            font-size: 16px;
            font-weight: 600;
            color:$textOff;
        }
        .text{
            word-break: break-all;
        }
    }

    .time {
        font-size: 14px;
        font-weight: 100;
        opacity: 0.7;
    }

    .text {
        font-size: 16px;
        margin: 10px 0;
    }

    .img-main {
        width: 100%;
        object-fit: contain;
        max-height: 832px;
        border-radius: 20px;
        margin: 20px 0;
    }

    video {
        width: 100%;
        max-height: 832px;
        border-radius: 20px;
        margin: 20px 0;
    }

    .poll {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 20px 0;

        .opt-wrapper {
            width: 100%;
            @include flex(0, flex-start, center);
            border: 2px solid $main3;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            .text-wrapper {
                position: relative;
                @include flex(10px, flex-start, center);
                flex: 1;
                min-height: 60px;

                .text {
                    flex: 1;
                    padding: 10px 0;
                    z-index: 1;
                }

                .bar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    background-color: $main3;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                p {
                    margin: 0 5px;
                    z-index: 1;
                }
            }

            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }

        .chosen{
            border: 2px solid $main7 !important;
        }
    }

    .profile-mini{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
    }

    .lower {
        @include flex(0, space-between, center);
        margin-bottom: 20px;

        .actionable {
            color: $text;
            span {
                cursor: pointer;
                color:$main7;
                font-size: 24px;
            }

            &:hover {
                background-color: $main3;
            }
        }

        div {
            @include flex(5px, flex-start, center);
            padding: 2px 6px;
            border-radius: 10px;
        }

        p{
            font-size: 14px;
        }
    }

    .add-comment {
        @include flex(10px, flex-start, center);
        margin-bottom: 30px;

        input {
            flex: 1;
            height: 40px;
            border: none;
            background-color: $main3;
            padding-left: 10px;
            color: #ffff;
            border-radius: 10px;
            font-size: 16px;
            padding-right: 10px;
        }

        button {
            background-color: $main7;
            color: white;
            border: none;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            @include flex(0, center, center);
            transform: scaleX(-1);

            span {
                font-size: 20px;
            }
        }
    }

    .show-replies{
        @include flex(0px, center, center);
        color: $main6;
        cursor: pointer;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 852px) {
    .post {
        .img-main,video{
            max-height: 600px;
        }
    }  
}