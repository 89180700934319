.comment {
    @include flex(10px, flex-start, flex-start);
    margin-bottom: 20px;
    width: 100%;

    p {
        word-break: break-word;
    }

    .add-comment {
        margin-bottom: 10px !important;
        width: 100%;

        input {
            width: calc(100% - 80px) !important;
        }
    }

    .name {
        @include flex(10px, flex-start, center);
        margin-bottom: 10px;
        p {  
            font-size: 14px;
            opacity: 0.8;
        }

        span {
            font-size: 12px;
            margin-left: 10px;
            color: white;
            opacity: 0.7;
        }
    }

    .lower-comment {
        @include flex(10px, flex-start, center);
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;

        span {
            font-size: 16px;
            cursor: pointer;
            color: $main7;
        }

        .reply {
            margin-right: 10px;
            cursor: pointer;
            color: $main7;
        }
    }

    .show-replies {
        @include flex(0px, center, center);
        color: $main6;
        cursor: pointer;
        margin-bottom: 10px;
    }
}