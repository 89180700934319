.boarding {
    @include flex(0, center, center, column);
    height: 517px;
    padding: 20px;

    h1 {
        font-size: 26px;
        font-weight: 900px;
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid $main7;
        width: 100%;
        text-align: center;
    }

    label {
        padding-top: 30px;
        img {
            height: 120px;
            aspect-ratio: 1;
            object-fit: cover;
            cursor: pointer;
        }
    }

    .inputs {
        width: 100%;
        @include flex(40px, center, center);
    }

    input {
        width: 100%;
        height: 40px;
        border: 1px solid $main7;
        background-color: $main3;
        color: $white;
        border-radius: 10px;
        padding: 0 10px;
    }

    .buttons {
        width: 100%;
        flex: 1;
        @include flex(5px, flex-end, center, column);
    }

    button {
        @include flex(0, center, center);
        height: 50px;
        border-radius: 10px;
        width: 100%;
        text-align: center;
        color: $white;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
    }

    .lighter {
        background-color: $main3;
    }

    .bolder {
        background-color: $main7;
    }

}