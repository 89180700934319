.block-list {
    background-color: $main3;
    padding: 10px;
    border-radius: 10px;
    height: 400px;
    @include flex(0, space-between, center, column);

    h1 {
        font-size: 26px;
        font-weight: 900px;
        padding-bottom: 20px;
        margin: 10px;
        border-bottom: 1px solid $main7;
        width: 100%;
        text-align: center;
    }

    .close{
        border-radius: 10px;
        background-color: $main7;
        padding: 5px 30px;
        cursor: pointer;
    }

    .inner {
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }

    .blocked-user {
        @include flex(0, flex-start, center);

        div {
            flex: 1;
            @include flex(10px, flex-start, center);
        }

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
        }

        p {
            font-size: 1.2rem;
            color: $text;
        }

        button {
            background: $main7;
            color: $text;
            padding: 5px 10px;
            border-radius: 5px;
            margin-left: auto;
            cursor: pointer;
        }
    }
}