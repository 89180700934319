.popup-block-user {
    @include flex(10px, flex-start, center, column);
    padding: 10px 0;
    .level {
        @include flex(10px, center, center);
    }
    
    img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
    }
    
    button {
        width: 100%;
        height: 40px;
        background-color: $red;
        border-radius: 10px;
        color:$text;
    }
}