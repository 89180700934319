.login {
    @include flex(0, flex-start, center, column);
    padding: 20px;

    div{
        width: 100%;
    }

    form {
        width: 100%;
        @include flex(0, flex-start, flex-start, column);
        flex: 1;
    }

    * {
        margin: 0;
    }

    h1 {
        font-size: 26px;
        font-weight: 900px;
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid $main7;
        width: 100%;
        text-align: center;
    }

    .instructions {
        font-size: 18px;
        font-weight: 600;
        color: $white;
        opacity: 0.8;
        margin-bottom: 20px;
        span {
            color: $main7;
        }
    }

    .privacy{
        color:$textOff;
        margin-bottom: 10px;
    }

    select,
    input {
        color: white;
        background-color: $main3;
        border-radius: 10px;
        font-size: 16px;
        box-shadow: 0px 0px 12px 0px $main7;
        border: 1px solid $main7;
        width: 100%;
        height: 45px;
        text-align: center;
    }

    .wrong{
        color: $main7;
        text-align: left;
        text-decoration: underline;
        margin-top: 10px;
        cursor: pointer;
    }
}