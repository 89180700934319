.social {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    p{
        margin:0;
    }

    .verified {
        width: 16px;
        height: 16px;
        object-fit: cover;
        padding-bottom: 5px;
    }

    .saltiz{
        @include flex(5px, center, center);
        font-weight: 900;
        font-size: 20px;
        img{
            width: 16px;
        }
    }

    .image {
        width: 80px;
        height: 80px;
        border-radius: 60px;
    }

    .soc {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        color:$main7;
        cursor: pointer;
    }

    .details {
        height: 60px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
    }

    .inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .socialIcon {
        width: 20px;
        height: 20px;
    }

}