.no-tickets-wrapper {
    height: calc(100vh - 60px);
    @include flex(0, center, center);
    color: white;

    .no-tickets {
        @include flex(10px, center, center, column);

        img {
            width: 300px;
            margin: auto;
        }

        p {
            text-align: center;
            font-size: 30px;
            margin: 0;
        }
    }
}

.tickets {
    padding: 10px;
    flex: 1;
    @include flex(0, space-between, center, column);
    height: calc(100vh - $headerHeight);

    .upper {
        width: 100%;
        @include flex(0, space-between, center);
    }

    .show-banner {
        width: 100%;
        aspect-ratio: 2.33;
        margin-bottom: 20px;
    }

    h1 {
        margin: 0;
        font-weight: 900;
        font-size: 28px;
    }

    h3 {
        font-size: 22px;
        font-weight: 900;
    }

    .three-wrapper {
        @include flex(5px, flex-start, center);
    }

    .three-images {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        height: 34px;
        width: 70px;

        img {
            width: 34px;
            height: 34px;
            object-fit: cover;
            border-radius: 17px;
            position: absolute;
            border: 2px solid $main1;
        }
    }

    button {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: $main7;
        font-size: 20px;
        font-weight: 900;
        color: $text;
        margin-bottom: 20px;
    }

    .pulsing {
        animation: pulsing 2s infinite;
    }


}

//animation of shadow box goes larger and smaller 0 25 50 75 100
@keyframes pulsing {
    0% {
        box-shadow: 0 0 10px rgba(72, 124, 252, 0.8),
            0 0 20px rgba(72, 124, 252, 0.6),
            0 0 30px rgba(72, 124, 252, 0.4);
    }

    50% {
        box-shadow: 0 0 20px rgba(72, 124, 252, 1),
            0 0 40px rgba(72, 124, 252, 0.8),
            0 0 60px rgba(72, 124, 252, 0.6);
    }

    100% {
        box-shadow: 0 0 10px rgba(72, 124, 252, 0.8),
            0 0 20px rgba(72, 124, 252, 0.6),
            0 0 30px rgba(72, 124, 252, 0.4);
    }
}