.home {
    min-height: calc(100vh - 60px);

    .inner-home {
        padding: 10px;
    }

    .creator-banner {
        position: relative;
        width: 100%;
        z-index: 2;
        aspect-ratio: 6.2;
        margin-bottom: 20px;
    }

}