.logo-middle {
    width: 250px;
    object-fit: contain;
    margin-bottom: 30px;
    z-index: 2;
}

.welcome {
    @include flex(0, center, center, column);
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;

    .half {
        @include flex(0, center, center, column);
        position: relative;
        z-index: 1;
        width: 100%;
    }

    .title {
        font-size: 36px;
        color: $main7;
        font-weight: 900;
        margin-bottom: 5px;
    }

    .subtitle {
        font-size: 24px;
        color: $white;
        font-weight: 700;
        margin-bottom: 20px;
        margin: 0;
    }

    .liveIn {
        font-size: 24px;
        color: $white;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .timer {
        color: $main7;
        font-size: 40px;
    }

    .info {
        font-size: 18px;
        color: $white;
        width: 90%;
        text-align: center;
        margin-bottom: 30px;
    }

    .logo {
        width: 200px;
        object-fit: contain;
    }

    .background {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: 10px;
    }
}