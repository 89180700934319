.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
    background-color: $main3;
    @include flex(40px, space-between, center);
    font-size: 24px;
    z-index: 9;
    padding: 8px 3vw;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    direction: ltr;

    .logo {
        height: 35px;
        object-fit: contain;
        cursor: pointer;
    }

    .header-options-bar {
        @include flex(30px, space-between, center);
        position: relative;

        img {
            height: 26px;
            height: 26px;
            object-fit: cover;
            cursor: pointer;
        }

        .header-user-img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
            outline:2px solid $white;
        }

        .border-main{
            outline: 2px solid $main7;
        }
    }


    .mobile-search-wrapper {
        height: 40px;
        width: 100%;
        @include flex(5px, center, center);
    }

    .menu-icon {
        font-size: 40px;
    }
}