.footer {
    background-color: $main3;
    @include flex(0, space-between, center);
    width: 100%;
    padding: 0 50px;
    margin-bottom: 0;

    p {
        font-size: 13px;
        color: white;
        cursor: pointer;
        text-decoration: underline;
    }

    p::first-letter {
        text-transform: capitalize;
    }

    .contact {
        @include flex(10px, center, center);

        img {
            width: 20px;
            aspect-ratio: 1/1;
            object-fit: cover;
            cursor: pointer;
        }

    }
}

@media (max-width: $middle) {
    .footer {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        margin-bottom: 40px;

        p {
            margin: 3px;
        }
    }
}