.cards-wrapper {
    width: 100%;
    @include flex(10px, flex-start, center);
    flex-wrap: wrap;
}

.show-more-tickets{
    @include flex(5px, center, center);
    color: $main7;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 20px;
}

.show-card {
    width: calc(50% - 10px); 
    max-width: 340px; 
    background-color: $main3;
    border-radius: 10px;
    flex-shrink: 0;
    flex-grow: 1; 
    margin-bottom: 20px;

    h1 {
        margin: 0;
        font-size: 20px;
        font-weight: 900;
        margin-right: 5px;
    }

    p {
        margin-right: 5px;
    }

    img {
        width: 100%;
        aspect-ratio: 16/9;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    button {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background-color: $main7;
        font-size: 20px;
        font-weight: 900;
        color: $text;
    }
}