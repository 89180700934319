//basics
@import "/src/style/base/variables";
@import "/src/style/base/mixins";
@import "/src/style/base/helpers";
@import "/src/style/base/font-face";

//pages
@import "/src/style/pages/home";
@import "src/style/pages/profile";
@import "src/style/pages/stream";
@import "src/style/pages/boarding";
@import "src/style/pages/chat";
@import "src/style/pages/tickets";
@import "src/style/pages/login";
@import "src/style/pages/privacy-policy";

//components
@import "src/style/cmps/welcome";
@import "/src/style/cmps/header";
@import "/src/style/cmps/countdown";
@import "/src/style/cmps/feed";
@import "src/style/cmps/error";
@import "src/style/cmps/show-card";
@import "/src/style/cmps/comment";
@import "/src/style/cmps/block-list";
@import "/src/style/cmps/social";
@import "src/style/cmps/menu";
@import "src/style/cmps/popup";
@import "src/style/cmps/options-bar";
@import "src/style/cmps/popup-block-user";
@import "src/style/cmps/accessibility";
@import "src/style/cmps/date-time";
@import "src/style/cmps/loader";
@import "src/style/cmps/footer";
@import "src/style/cmps/upper-popup";
@import "src/style/cmps/simple-popup";
@import "src/style/cmps/popup-details";
@import "src/style/cmps/popup-pay";
@import "src/style/cmps/wallpaper";

* {
  box-sizing: border-box;
  font-family: rubik-medium;
  scroll-behavior: smooth;
}

//layout

html {
  padding-right: 0 !important;
  overflow-y: scroll;
  direction: rtl;
}

body {
  margin: 0;
  width: 100%;
  background: $main1;
  color: $text;
}

.main-layout {
  margin-top: $headerHeight;
  min-height: calc(100vh - $headerHeight);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inner-layout {
  flex: 1;
  background: $main1;
}

.narrow-layout {
  width: 100%;
  max-width: 750px;
  z-index: 2;
  background-color: $main1;
  position: relative;
  margin: 0 auto;
}

.download{
  width: 100%;
  height: 99vh;
  padding: 10px;
  @include flex(10px, center, center,column);
  button{
    width: 100%;
    height: 50px;
    background-color: $main7;
    color: $text;
    border-radius: 10px;
    font-size: 26px;
  }
}

// defaults

textarea {
  resize: none;
}

textarea:focus,
input:focus {
  outline: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: transparent;
}

input[type='range'] {
  overflow: hidden;
  width: 130px;
  -webkit-appearance: none;
  background-color: $main3;
  height: 10px;
  border-radius: 10px;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: black;
  margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
  width: 12px;
  -webkit-appearance: none;
  height: 30px;
  cursor: pointer;
  background: $main6;
  box-shadow: -80px 0 0 80px $main7;
}


// make the scroll bar transperent
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}