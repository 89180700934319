.upper-popup{
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    width: fit-content;
    min-width: 300px;
    text-align: center;
    top: 10px;
    background: white;
    border-radius: 10px;
    text-align: center;
    background-color: $main1;
    padding: 10px;
    animation: slide-down 0.5s ease;
    p{
        font-size: 24px;
        margin: 0;
        color:$main7;
    }
}

@keyframes slide-down {
    0% {
        top: -20px;
    }
    100% {
        top: 10px;
    }
}