$banner-height: 300px;
$img-width: 150px;
$banner-mobile: 240px;
$img-mobile: 120px;

.editable {
    position: absolute;
    top: -160px;
    right: 0;
    @include flex(0, flex-start, center);
    height: 180px;
    width: 100%;
    .user-img {
        width: $img-width;
        height: $img-width;
        border-radius: 50%;
        object-fit: cover;
        border: 5px solid rgb(233, 233, 233);
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }
    
    .img-cover {
        @include flex(0px, center, center);
        background-color: black;
        opacity: 0;
        cursor: pointer;
    
        span {
            color: white;
        }
    
        input {
            display: none;
        }
    }
    
    .img-cover:hover {
        opacity: 0.6;
    }
}

.profile {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
    color: white;
    padding-bottom: 20px;

    h3 {
        font-size: 24px;
        margin: 15px 0;
        color: $main7;
        font-weight: 700
    }

    .details {
        @include flex(0, flex-start, flex-start, column);
        height: 30px;
        margin: 25px 0;
        position: absolute;
        bottom: 20px;
        right: 153px;

        h1 {
            font-size: 26px;
            margin-bottom: 4px;
            margin-top: 0;
        }

        div {
            @include flex(10px, flex-start, center);
        }

        .name-holder {
            @include flex(0, space-between, center)
        }

        form {
            @include flex(10px, center, center);

            * {
                color: white;
                background-color: transparent;
            }

            input {
                height: 33px;
                font-size: 20px;
                border-width: 0;
            }

            input:focus {
                border-width: 0;
                outline: 0 solid black;

            }

            button {
                border-width: 0;
                cursor: pointer;
            }
        }
    }

    .no-tickets {
        width: 100%;
        height: 220px;
        background-color: $main3;
        margin-bottom: 20px;
        @include flex(0, center, center, column);

        img {
            width: 40px;
            object-fit: contain;
            margin-bottom: 10px;
        }

        p{
            margin: 0;
        }

        .to-tickets {
            text-align: center;
            font-weight: 700;
            background-color: $main7;
            cursor: pointer;
            border-radius: 5px;
            padding: 10px 20px;
            margin-top: 10px;
        }
    }

    .img-holder {
        @include flex(0px, center, center, column);
        z-index: 2;
        margin-bottom: 20px;

        .profile-banner {
            width: 100%;
            height: $banner-height;
            object-fit: cover;
            object-position: bottom;
        }
    }

    .profile-wrapper {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .list-item {
        background-color: $main3;
        padding: 0 10px;
        height: 40px;
        margin-bottom: 1px;
        @include flex(10px, space-between, center);
        cursor: pointer;
    }

}