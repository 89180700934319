.date-time {
    @include flex(20px, center, center);
    height: 50px;

    img {
        width: 16px;
        height: 16px;
        margin-left: 2px;
        object-fit: cover;
    }

    p {
        font-weight: 600;
        margin:0;
    }

    .main {
        color: $main7;
    }

    .white {
        color: $white;
    }
}