$mobile : 700px;
$middle : 965px;
$headerHeight : 60px;
$main1: #040616;
$main2: #090E25;
$main3: #0D1533;
$main4: #152450;
$main5: #264189;
$main6: #375FC3;
$main7: #487CFC;
$text: #ffffff;
$textOff: #d1d1d1;
$white: #ffffff;
$red: #FF4747;
$gray: #818181;