.error{
    @include flex(0px, center, center,column);
    margin: 20px 0;

    img{
        width: 120px;
    }
    h1{
        color: white;
        font-size: 24px;
        margin: 10px 0;
        text-align: center;
    }
    p{
        color:rgba(255,255,255,0.7);
        font-size: 18px;
        margin: 0;
        text-align: center;
        span{
            color: $main7;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    div{
        @include flex(0, center, center);
        font-size: 18px;
        margin-top: 20px;
        color: white;
        background-color: $main7;
        padding: 5px 30px;
        border-radius: 10px;
        cursor: pointer;  
    }
}
@media screen and (max-width: $mobile) {
    .error{
        padding: 0 10px;
        margin-top: 100px;
        img{
            width: 100px;
        }
        h1{
            font-size: 22px;
        }
        p{
            font-size: 16px;
        }
        div{
            font-size: 26px;
            padding: 5px 20px;
        }
    }
    
}