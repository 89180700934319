.after-pay {
    padding: 10px;
    padding-bottom: 20px;
    text-align: center;
    @include flex(0, center, center,column);
    img {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: 10px;
    }
    p{
        font-size: 20px;
        margin: 0;
        color: $main7;
    }
}

.popup-pay {
    .paypal {
        border-radius: 10px;
        background: radial-gradient(circle at center, $main6 ,$main7 );
        padding: 10px;

        p {
            margin-top: 0;
            color:white;
            @include flex(0, flex-start, center);
            font-size: 22px;

            .align {
                padding-bottom: 4px;
            }
        }
    }

    .exit {
        color: white;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 999999;
        border-radius: 50%;
    }

    .failed {
        padding: 10px;
    }
}

@media (max-width: 700px) {
    .popup-pay {
        .paypal {
            height: 80vh;
            overflow-y: scroll;
        }
    }
}