.underline {
    text-decoration: underline;
}

.hover-main:hover {
    color: $main7;
}

.center {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-row-10{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.center-start{
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
}

.center-horizontal{
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.space-between-vertical{
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: space-between !important;
}

.cancel-animation{
    animation: none !important;
}

.inner-small{
    width: 638px;
    border-radius: 10px;
    background-color: $main2;
    position: relative;
    .button {
        @include flex(0, center, center);
        height: 50px;
        border-radius: 10px;
        width: 100%;
        text-align: center;
        color: $white;
        font-size: 20px;
        background-color: $main7;
        font-weight: 700;
        cursor: pointer;
    }
}

.non-appear {
    display: none;
}

.hidden {
    visibility: hidden;
}

.red-background{
    background: $red !important;
}

.red{
    color: $red !important;
}

.screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    background-color: black;
    z-index: 10;
}

.blur {
    opacity: 0.8;
}

.main-color {
    color: $main7;
}

.color-main{
    color: $main7;
}

.mirror{
    transform: scaleX(-1);
}

.screen-tutorial {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    background-color: black;
    z-index: 10;
    opacity: 0.8;
}

.clickable {
    cursor: pointer;
}

.undecorate {
    text-decoration: none;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input:focus {
    outline: 0px;
}