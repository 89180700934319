.chat-stream {
    width: 340px !important;
}

.question {
    padding: 10px;
    background-color: $main3;
    border-radius: 10px;
    width: fit-content;
}

.answer {
    padding: 10px;
    background-color: $main5;
    border-radius: 10px;
    width: 630px;
    margin-right: 70px;
}

.model-menu {
    position: absolute;
    top: $headerHeight;
    right: 0;
    width:200px;
    p{
        background-color: $main3;
        width: 100%;
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        margin: 5px;
    }
    .selected{
        background-color: $main7;
    }
}

.chat {
    width: 750px;
    @include flex(0, flex-start, flex-start, column);
    height: calc(100vh - $headerHeight);

    * {
        word-break: break-word;
    }

    .messages-container {
        overflow-x: scroll;
        display: flex;
        flex-direction: column;
        flex: 1;
        word-break: break-word;
        width: 100%;
    }

    .image-wrapper {
        width: 100%;
        @include flex(0, flex-start, flex-start);
        margin: 10px;
    }

    .attached-image {
        max-width: 550px;
        max-height: 550px;
        object-fit: contain;
        border-radius: 10px;
    }

    .input-container {
        width: 100%;
        @include flex(10px, flex-start, center);
        padding-bottom: 15px;

        input {
            flex: 1;
            height: 40px;
            border: none;
            background-color: $main3;
            padding-right: 10px;
            text-align: right;
            color: $white;
            border-radius: 10px;
            font-size: 18px;
            border: 1px solid $main7;
        }

        span {
            color: $white;
            font-size: 25px;
            background-color: $main7;
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .chat-header {
        width: 100%;
        @include flex(10px, flex-start, center);
        background-color: $main3;
        padding: 5px 10px;
        border-radius: 10px;

        .verified {
            width: 16px;
            object-fit: contain;
        }

        .saltiz-image {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }

        .line {
            @include flex(5px, flex-start, center);
        }

        p {
            font-size: 20px;
            margin: 0
        }
    }

    .message {
        margin: 5px;
        cursor: pointer;

        .saltiz {
            display: inline-flex;
            margin-left: 5px;
        }
    }

    .broad-message {
        @include flex(5px, flex-start, flex-start);
        cursor: pointer;
        margin-top: 30px;
        border-radius: 10px;

        p {
            margin: 0;
        }

        .inner {
            @include flex(5px, flex-start, flex-start, column);
            padding-left: 5px;
            flex: 1;
        }

        .name {
            color: var(--white);
            font-size: 16px;
            text-align: right;
            font-weight: bold;
        }

        .mini {
            font-size: 12px;
            color: var(--textOff);
        }

        .text {
            color: var(--white);
            font-size: 16px;
            text-align: right;
            flex: 1;
        }

        .user-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .line {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
        }
    }

    .saltiz {
        background-color: $main3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 5px;
        border-radius: 13px;
        height: 26px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .verified {
        width: 14px;
        object-fit: contain;
    }

    .pinned {
        @include flex(2px, flex-start, flex-start);
        border-bottom: 2px solid $main3;
        padding: 10px;
        width: 100%;

        .pin {
            width: 16px;
            object-fit: contain;
            align-self: flex-start;
            margin-top: 2px;
        }

        p {
            display: block;
            margin: 0
        }
    }

    .reconnect {
        width: 100%;

        p {
            color: $red;
            text-decoration: underline;
            text-align: center;
            cursor: pointer;
        }
    }
}