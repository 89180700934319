.stream {
    @include flex(0, flex-start, flex-start);

    .stream-inner {
        flex: 1;
        position: relative;
        width: 100%;
        height: calc(100vh - $headerHeight);
        @include flex(0, center, center, column);
    }

    #agora_remote {
        width: 100%;
        aspect-ratio: 16/9;
        background-color: #000000;
        position: relative;

        .inner-volume {
            position: absolute;
            @include flex(0, center, center);
            bottom: 10px;
            right: 102px;
            background-color: rgba($color: #000000, $alpha: 0.7);
            padding: 5px;
            border-radius: 5px;
            color: $white;
            cursor: pointer;
            z-index: 2;

            span {
                font-size: 17px;
            }
        }
    }

    .over-video {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flex(0, center, center, column);
        background-color: #000000;
    }

    .msg {
        color: $main7;
        font-size: 2rem;
    }

    .stream-bar {
        @include flex(0, space-between, center);
        padding: 0 10px;
        position: absolute;
        width: 100%;
        height: 40px;
        background-color: $main3;
        opacity: 0.7;
        z-index: 2;
        bottom: 0;
    }

    .viewers-img {
        width: 20px;
        height: 20px;
    }
}