.countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 35px;

    .item {
        width: 88px;
        height: 110px;
        border: 1px solid white;
        border-radius: 10px;
        @include flex(0, center, center, column);
        background-color: rgba(255, 255, 255, 0.7);
    }

    .number {
        font-size: 32px;
        color: black;
        font-weight: 700;
        margin: 0;
        margin-bottom: 10px;
        padding-bottom: 10px;
        width: 75px;
        text-align: center;
        border-bottom: 2px solid black;
    }

    .time {
        margin: 0;
        font-size: 18px;
        color: black;
        font-weight: 100;
    }
}

.stream {
    .countdown {
        .item {
            background-color: rgba(255, 255, 255, 0);
            border-width: 0;
        }

        .number {
            color: $main7;
            font-size: 32px;
            border-bottom: 1px solid $main7;
        }

        .time {
            color: $main7;
            font-size: 18px;
        }
    }
}

.countdown-mini {
    gap: 0;

    .item {
        width: 40px;
        height: 55px;
        border: none;
        border-radius: 0;
        background-color: $main3;
        padding: 42px 35px;
    }

    .number {
        font-size: 20px;
        color: $main7;
        text-align: center;
        border-bottom: 1px solid $gray;
        font-weight: bold;
        width: 40px;
        margin-bottom: 3px;
        padding-bottom: 3px;
    }

    .time {
        margin: 0;
        font-size: 16px;
        color: $textOff;
    }
}